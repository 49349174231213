import { SSRProvider } from "@react-aria/ssr";
import React from "react";
import Footer from "../components/Footer/footer";
import { Head } from "../components/Header/head";
import { Navbar } from "../components/Navbar/navbar";
import "../styles/agradecimientos.css";
import agradecimientos from '../assets/images/agradecimientos.jpg'
const Agradecimientos = () => {
  return (
    <SSRProvider>
      <Head title="Posada Los Jerónimos - Agradecimientos" />
      <Navbar />

      <section id="agradecimientos" className="agradecimientos">
        <h2>Agradecimientos</h2>
        <p>
          Como “de bien nacidos es ser agradecidos”, es de ley agradecer a las
          siguientes personas:
        </p>
        <p>
          <b> Braulio Villa</b>, a lo largo de estos más de 22 años, ha sido el
          mayor artífice de todas las obras que hemos ido realizando. Casi toda
          la piedra y madera que hay en nuestra Posada ha pasado por sus manos.
          Además de su trabajo en cocina, pinche número uno. En fin, un gran
          compañero de trabajo, siempre con buen humor, fundamental en Los
          Jerónimos.
        </p>
        <p>
          <b> Mari Luz Altamira y Eloy Matilla </b> han sido generosos
          patrocinadores en varias de las fases de las obras acometidas, que nos
          han permitido no meternos en grandes préstamos bancarios.
        </p>
        <p>
          <b> Pedro Lucas del Teso</b>, arquitecto que nos ha ayudado en todas
          las fases realizadas, con su gran trabajo, a realizar la reunificación
          de lo que fue el cenobio de los Jerónimos, ahora reconvertido en
          Posada rural, con su visión respetuosa de la arquitectura tradicional
          y su apuesta por los materiales nobles.
        </p>
        <p>
          <b>Eugenio González García</b>, cliente primero, gran amigo después,
          nos ha aconsejado de cómo realizar muchas cosas que no hacíamos bien
          (o eran claramente mejorables, jeje), fue el ideólogo de las jornadas
          de cocina peruana y ha sido el gran cocinero en las 8 jornadas de
          cocina de setas de manera totalmente desinteresada.
        </p>
        <p>
          <b> Todos los trabajadores que han pasado por la Posada </b>, Carla
          Villa, William Succa, Humberto Villena, Josefina Pérez, Carlines
          Villa, Lucy Villa, Henry Huayhua, que se han involucrado con nuestro
          proyecto, que han entendido que en un trabajo en equipo todos sumamos
          y con buen rollo es mucho más fácil, una maravilla de personal. Lo
          cual se puede repetir de los actuales Marco Pérez, Raquel, Álvaro y
          Natalio. Grandes.
        </p>
        <p>
          <b> Toda la familia y los amigos</b> que nos habéis visitado en este
          tiempo, que nos habéis seguido y apoyado en nuestros altibajos.
        </p>
        <p>
          <b> Todos los clientes </b>que venís, por haberos sentido como en
          vuestra casa, eso es lo que pretendemos.
        </p>
        <p>
          <b>Unión Europea</b>, por su apoyo financiero a través de los Fondos
          FEDER, con el emblema de Castilla y León “Europa impulsa nuestro
          crecimiento”, nos ha subvencionado tanto el punto de recarga para
          coches eléctricos como las terrazas exteriores adaptadas para poder
          dar comidas y cenas en exterior.
        </p>

        <img src={agradecimientos}/>
      </section>

      <Footer slim />
    </SSRProvider>
  );
};

export default Agradecimientos;
